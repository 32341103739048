import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['element'];
  }

  connect() {
    this.timer = setTimeout(() => {
      this.remove();
    }, 20000);
  }

  close(e) {
    e.preventDefault();

    this.remove();
    if (this.timer) clearTimeout(this.timer);
  }

  remove() {
    this.elementTarget.classList.add('slide-out');
    setTimeout(() => {
      this.elementTarget.remove();
    }, 1000);
  }
}
