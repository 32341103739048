import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['input'];
  }

  preview(e) {
    e.preventDefault();

    if (this.inputTarget.type === 'password') {
      this.inputTarget.type = 'text';
      this.inputTarget.autocomplete = 'off';
    } else {
      this.inputTarget.type = 'password';
      this.inputTarget.autocomplete = 'current-password';
    }
  }
}
