// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@stimulus/polyfills';
import Rails from 'rails-ujs';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import axios from 'axios';

Rails.start();

const application = Application.start();
const context = require.context('controllers', true, /.js$/);
application.load(definitionsFromContext(context));

axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken();
axios.defaults.headers.common.Accept = 'application/json';

// Maintain a global counter for Axios active requests.
// See Capybara helper implemented in `spec/support/wait_for_axios.rb`.
axios.interceptors.request.use((config) => {
  window.pendingRequests = window.pendingRequests || 0;
  window.pendingRequests += 1;
  return config;
});
axios.interceptors.response.use((response) => {
  window.pendingRequests -= 1;
  return response;
}, (error) => {
  window.pendingRequests -= 1;
  return Promise.reject(error);
});

// Load and expose Honeybadger.
global.Honeybadger = require('honeybadger-js');

// Load and expose jQuery.
global.jQuery = require('jquery');
global.$ = global.jQuery;

document.addEventListener('keydown', function (event) {
  if (event.keyCode === 13 && event.target.nodeName === 'INPUT') {
    var form = event.target.form;
    var index = Array.prototype.indexOf.call(form, event.target);

    if (event.target.type !== 'password') {
      form.elements[index + 1].focus();
      event.preventDefault();
    } else {
      form.submit();
    }
  }
});
