
import { Controller } from 'stimulus';

const emailRegex = /\S+@\S+\.\S+/;

export default class extends Controller {
  static get targets() {
    return ['email', 'sendMeResetPassword'];
  }

  validateEmail(e) {
    this.sendMeResetPasswordTarget.classList.add('btn--disabled');
    if (emailRegex.test(e.target.value)) {
      this.sendMeResetPasswordTarget.classList.remove('btn--disabled');
    } else {
      this.sendMeResetPasswordTarget.classList.add('btn--disabled');
    }
  }
}
