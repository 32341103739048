import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['input', 'box1', 'submit'];
  }

  connect() {
    this.inputTarget.focus();
    this.inputTarget.addEventListener('mousemove', (e) => {
      e.preventDefault();
      this.inputTarget.setSelectionRange(0, 0);
    });
    this.box1Target.classList.add('two-fa__box--active');
    this.submitTarget.classList.add('btn--disabled');
  }

  setActiveBox(e) {
    const length = e.target.value.length;
    const boxes = document.getElementsByClassName('two-fa__box');

    Array.from(boxes).forEach(box => box.classList.remove('two-fa__box--active'));

    if (length > 0 && length < 6) {
      const className = `two-fa__box--${length + 1}`;
      document.getElementsByClassName(className)[0].classList.add('two-fa__box--active');
      this.submitTarget.classList.add('btn--disabled');
    } else if (length === 6) {
      this.submitTarget.classList.remove('btn--disabled');
      this.inputTarget.blur();
      this.inputTarget.setSelectionRange(0, 0);
    } else if (length === 0) {
      this.box1Target.classList.add('two-fa__box--active');
      this.submitTarget.classList.add('btn--disabled');
    }
  }
}
